<template>
  <div class="row m-0">
    <div class="col-12 px-0">
      <!--DESKTOP-->
      <div class="row font13 green-text-dark d-none d-lg-flex">
        <div v-for="item, index in headerItems" :key="index" class="col bold text-md-center pe-0" :class="{'ps-0': index === 0, 'ps-1': index !== 0}">
          <div class="lightgrey_bg w-100 h-100 p-3" :class="{'rounded-left': index === 0, 'rounded-right': index === headerItems.length - 1}">
            {{item.displayDesktop}}
          </div>
        </div>
      </div>
      <!--END DESKTOP-->
      <!--MOBILE-->
      <div class="row font13 green-text-dark d-lg-none">
        <div class="col bold text-center px-0" v-for="item, index in mobileHeaderItems" :key="index">
          {{item.displayMobile}}
        </div>
      </div>
      <!--END MOBILE-->
    </div>
  </div>
</template>

<script>

export default {
  name: 'RecurringTableHeader',
  props: ['headerItems', 'mobileHeaderItems']
}
</script>

<style scoped>
.rounded-left {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
}
.rounded-right {
  border-bottom-right-radius: 100px;
  border-top-right-radius: 100px;
}
</style>
