<template>
  <div class="row mx-0 mt-5">
    <div class="col-12 text-center">
      <div class="d-inline p-2 cursor" v-for="index in totalPages" :key="index" @click="goToPage(index)" :class="{bold: value === index - 1}">
        {{ index }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TablePagination',
  props: ['modelValue', 'itemsPerPage', 'totalItems'],
  emits: ['update:modelValue', 'goToPage'],
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (val) {
        this.$emit('update:modelValue', val)
      }
    },
    totalPages () {
      return Math.ceil(this.totalItems / this.itemsPerPage)
    }
  },
  methods: {
    async goToPage (val) {
      await this.$emit('goToPage', (val - 1))
      this.value = val - 1
    }
  }
}
</script>
