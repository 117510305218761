<template>
  <div class="row mx-0">
    <div class="col-12 p-0" v-if="countRecurringDonations > 0">
      <TableHeader :headerItems="headerItems" :mobileHeaderItems="mobileHeaderItems" />
      <div class="w-100 text-center">
        <div class="spinner-border spinner-border-sm" role="status" v-if="allRecurringDonationsStatus === 'loading'">
          <span class="visually-hidden">Downloading...</span>
        </div>
      </div>
      <TableItem v-for="(donation, index) in allRecurringDonations.slice(start, finish)" :key="index" :donation="donation" :index="index" :status="allRecurringDonationsStatus" :headerItems="headerItems" :mobileHeaderItems="mobileHeaderItems" />
      <TablePagination v-model="currentPage" :itemsPerPage="itemsPerPage" :totalItems="countRecurringDonations" />
    </div>
    <div class="col-12 p-0 text-center" v-else>
      You don't have any Recurring Donations, set some up.
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'
import TableHeader from './TableHeader.vue'
import TablePagination from './TablePagination.vue'

export default {
  components: {
    TableItem: defineAsyncComponent(() => import('./TableItem.vue')),
    TableHeader,
    TablePagination
  },
  name: 'RecurringDonationsTable',
  props: ['transactionSearch'],
  mounted () {
    this.getTransactions(0, 20)
  },
  data () {
    return {
      itemsPerPage: 10,
      currentPage: 0,
      headerItems: [
        {
          displayDesktop: 'REFERENCE',
          displayMobile: 'Tran. Ref.',
          displayValue: 'recurringDonationID'
        },
        {
          displayDesktop: 'PAYMENT METHOD',
          displayValue: 'recurringType'
        },
        {
          displayDesktop: 'FREQUENCY',
          displayValue: 'frequency'
        },
        {
          displayDesktop: 'START DATE',
          displayMobile: 'Start Date',
          displayValue: 'startDate'
        },
        {
          displayDesktop: 'AMOUNT',
          displayMobile: 'Amount',
          displayValue: 'amount'
        },
        {
          displayDesktop: 'TOOLS',
          displayMobile: 'Info',
          displayValue: 'info'
        }
      ]
    }
  },
  watch: {
    transactionSearch () {
      this.currentPage = 0
    }
  },
  computed: {
    ...mapGetters(['user', 'countRecurringDonations', 'allRecurringDonations', 'allRecurringDonationsStatus']),
    mobileHeaderItems () {
      return this.headerItems.filter(item => {
        if (!item.displayMobile) {
          return false
        }
        return true
      })
    },
    start () {
      return this.currentPage * this.itemsPerPage
    },
    finish () {
      return this.start + this.itemsPerPage
    }
  },
  methods: {
    ...mapActions(['getAllRecurringDonations']),
    async getTransactions (startPosition, pageSize) {
      const options = {
        startPosition,
        pageSize
      }
      await this.getAllRecurringDonations(options)
    },
    goToPage (val) {
      if (val > this.currentPage) {
        const start = (this.currentPage * 10) + 10
        const pageSize = ((val - this.currentPage) * 10)
        this.getTransactions(start, pageSize)
      }
      this.$emit('goToDonations')
    }
  }
}
</script>
